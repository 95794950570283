import {
  Button,
  Icons,
  InvoiceState,
  Table,
  type ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';

import {
  AccountMove,
  InvoiceState as InvoiceStateType,
} from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import { assertNever } from '../../../../../helpers/type.helpers';
import { Sepa, SepaSpacer } from '../styles';

const formatInvoiceState = (i: AccountMove) => {
  switch (i.state) {
    case InvoiceStateType.Canceled:
      return `storniert am ${formatDate(i.canceledAt)}`;
    case InvoiceStateType.Paid:
    case InvoiceStateType.OverPaid:
      return `bezahlt am ${formatDate(i.paidAt)}`;
    case InvoiceStateType.UnderPaid:
      return `Teilzahlung am ${formatDate(i.partialPaidAt)}`;
    case InvoiceStateType.Open:
      return 'offen';
    default:
      assertNever(i.state);
  }
};

const mapInvoiceState = (
  i: AccountMove,
): React.ComponentProps<typeof InvoiceState>['state'] => {
  switch (i.state) {
    case InvoiceStateType.UnderPaid:
      return 'partially-paid';

    case InvoiceStateType.OverPaid:
      return 'paid';

    default:
      return i.state.toLowerCase().replace('_', '-') as React.ComponentProps<
        typeof InvoiceState
      >['state'];
  }
};

const mapColor = (i: AccountMove) => {
  switch (i.state) {
    case InvoiceStateType.Paid:
      return 'green';
    case InvoiceStateType.Canceled:
      return 'red';
    default:
      return undefined;
  }
};

const formatAmount = (i: AccountMove): string => {
  if (
    i.state === InvoiceStateType.UnderPaid ||
    i.state === InvoiceStateType.OverPaid
  ) {
    return `${formatCurrency(i.partialAmount!)} von ${formatCurrency(
      i.amount,
    )}`;
  }

  return formatCurrency(i.amount);
};

export const BookingTable: React.FC<{
  entries: AccountMove[];
  loading: boolean;
  onRowClick?: (item: AccountMove) => void;
  onEditClick?: (item: AccountMove) => void;
}> = ({ entries, loading, onRowClick, onEditClick }) => {
  const columns: ColumnDefinition<AccountMove>[] = [
    {
      Header: 'Status',
      accessor: 'state',
      width: '5%',
      Cell: ({ row: { original } }) => (
        <InvoiceState
          state={mapInvoiceState(original)}
          color={mapColor(original)}
          id={`${original.bookedAt}-${original.amount}-${original.state}-${original.sepaReturn}`}
          isSepaReturn={!!original.sepaReturn}
        />
      ),
    },
    {
      Header: 'Buchungsnummer',
      accessor: 'name',
      width: '20%',
    },
    {
      Header: 'Buchungsdatum',
      accessor: 'bookedAt',
      width: '20%',
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: 'Schritt',
      width: '15%',
      Cell: ({ row: { original } }) => formatInvoiceState(original),
    },
    {
      Header: 'Wert',
      accessor: 'amount',
      width: '20%',
      Cell: ({ row: { original } }) => (
        <>
          {original.sepa && <Sepa>sepa</Sepa>}
          {!original.sepa && <SepaSpacer />}
          {formatAmount(original)}
        </>
      ),
    },
    ...(onEditClick !== undefined
      ? [
          {
            Header: 'Bearbeiten',
            width: '5%',
            Cell: ({ row: { original } }) => (
              <Button
                secondary
                small
                onClick={onEditClick ? () => onEditClick(original) : undefined}
                aria-label="Bearbeiten"
              >
                <Icons.Pencil size="24" />
              </Button>
            ),
            disableSortBy: true,
          } as ColumnDefinition<AccountMove>,
        ]
      : []),
  ];

  return (
    <Table
      columns={columns}
      data={entries}
      isLoading={loading}
      withAlternatingRows
      onRowClick={onRowClick ? (row) => onRowClick(row.original) : undefined}
    />
  );
};
