import { Modal } from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import * as yup from 'yup';

import GraphQlForm from '../../../../../components/graphql-form';
import { GraphqlFormField } from '../../../../../components/graphql-form/render';

type UpdateBookingModalProps = {
  bookingName: string;
  bookingAmount: number;
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

export function UpdateBookingModal({
  bookingName,
  bookingAmount,
  isOpen,
  onClose,
  onSuccess,
}: UpdateBookingModalProps) {
  return (
    <Modal
      isOpen={isOpen === true}
      onRequestClose={onClose}
      contentLabel="Contract-Update-Booking-Modal"
      title="Buchung bearbeiten"
      minWidth={600}
    >
      <GraphQlForm
        mutation="updateBooking"
        startInEdit
        onSuccess={() => {
          onSuccess?.();
          onClose?.();
        }}
        onAbort={onClose}
        readDocumentFields={['success']}
        validation={{
          amount: yup
            .number()
            .moreThan(0)
            .required(
              'Bitte einen Wert im folgenden Format eintragen, z.B. 34,50.',
            )
            .test(
              'unchanged-value',
              'Bitte einen Wert größer oder kleiner als der bereits eingetragene Abschlag eintragen',
              (value) => {
                return value !== bookingAmount;
              },
            ),
        }}
        variables={{
          booking: {
            name: bookingName,
          },
        }}
        values={{
          name: bookingName,
          amount: bookingAmount,
        }}
      >
        <GraphqlFormField name="name" readOnly />
        <GraphqlFormField
          name="amount"
          type="number"
          step="0.01"
          inputMode="decimal"
          appendix="€"
        />
      </GraphQlForm>
    </Modal>
  );
}
