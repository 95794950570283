import {
  Button,
  FlexRow,
  InvoiceState,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ErrorMsg } from '../../../../../components';
import { useHasRole } from '../../../../../components/useHasRole';
import {
  AccountMove,
  AccountType,
  Booking,
  BookingType,
} from '../../../../../graphql-types';
import {
  formatCurrency,
  formatDate,
} from '../../../../../helpers/formatStrings';
import { Sepa } from '../styles';

const ModalWrap = styled.div`
  width: 350px;
`;

const ListHeading = styled.h3`
  font-family: 'Proxima Nova', sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
`;

const List = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
  font-size: 14px;
`;

const Item = styled.li`
  font-family: 'Proxima Nova', sans-serif;
  font-size: 15px;
  font-weight: 400;
  list-style: none;
  padding: 12px 0;
  border-bottom: 1px solid #cecece;
  &:last-child {
    border-bottom: none;
  }
`;

const ItemInner = styled(FlexRow)`
  justify-content: space-between;
`;

const ItemLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ItemRemaining = styled(Item)`
  font-weight: 600;
`;

const ButtonHint = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.palette.gray[700]};
`;

const CancelInvoiceHint = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.palette.warning.color};
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
`;

const CancelInvoiceButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const CancelInvoiceButton = styled(Button)`
  flex-grow: 1;
`;

function BookingItem({
  booking,
  label,
  bold,
}: {
  booking: Booking;
  label: string;
  bold?: boolean;
}) {
  const formattedAmount = formatMonetaryValue(
    booking.amount,
    booking.account ?? undefined,
  );

  const ItemWrapper = bold ? ItemRemaining : Item;

  return (
    <ItemWrapper>
      <ItemInner>
        <ItemLabel>{label}</ItemLabel>
        <div>{formattedAmount}</div>
      </ItemInner>
    </ItemWrapper>
  );
}

export type AccountingMoveDetailProps = {
  accountingMove: AccountMove;
  contractLabel: string;
  contractId: string;
  onClose: () => void;
  onCancelInvoice: ({
    contractNumber,
    contractId,
    bookingName,
    opcName,
    isPaid,
  }: {
    contractNumber: string;
    contractId: string;
    bookingName: string;
    opcName: string;
    isPaid: boolean;
  }) => void;
  error: boolean;
  isLoading: boolean;
};

export function AccountingMoveDetail({
  accountingMove,
  contractId,
  contractLabel,
  onClose,
  onCancelInvoice,
  error,
  isLoading,
}: AccountingMoveDetailProps) {
  const { hasRole: cancellationEnabled } = useHasRole(
    'feature_cancellation_accounting',
  );
  const [modalOpen, setModalOpen] = useState(false);

  const preventCancelInvoice = accountingMove.state === 'PAID';

  const isCanceled =
    accountingMove.canceledAt !== null &&
    accountingMove.canceledAt !== undefined;

  const cancelInvoice = useCallback(
    (isPaid: boolean) => {
      onCancelInvoice({
        opcName: accountingMove.opcName!,
        bookingName: accountingMove.name,
        contractNumber: contractLabel,
        contractId,
        isPaid,
      });
      setModalOpen(false);
    },
    [
      accountingMove.name,
      accountingMove.opcName,
      contractId,
      contractLabel,
      onCancelInvoice,
    ],
  );

  return (
    <Modal
      isOpen
      contentLabel="accounting-move-detail"
      title={accountingMove.name}
      onRequestClose={onClose}
    >
      <ModalWrap>
        {error && (
          <ErrorMsg
            error="Es gab einen Fehler, beim Versuch die Buchung zu stornieren. Bitte wende Dich unter Angabe
                der Vertrags- und Rechnungsnummer an den Service Desk."
            retryable={false}
          />
        )}
        <ListHeading>Buchungen</ListHeading>
        <List>
          {accountingMove.bookings &&
            renderBookings(accountingMove.bookings, isCanceled)}
        </List>
        <ListHeading>Verlauf</ListHeading>
        <List>
          <Item>
            <ItemInner>
              <ItemLabel>Gebucht am</ItemLabel>
              <div>{formatDate(accountingMove.bookedAt)}</div>
            </ItemInner>
          </Item>
          {accountingMove.paidAt && (
            <Item>
              <ItemInner>
                <ItemLabel>
                  <InvoiceState state="paid" id="paid" color="green" />
                  Bezahlt am
                  {accountingMove.sepa && <Sepa>sepa</Sepa>}
                </ItemLabel>
                <div>{formatDate(accountingMove.paidAt)}</div>
              </ItemInner>
            </Item>
          )}
          {accountingMove.canceledAt && (
            <Item>
              <ItemInner>
                <ItemLabel>
                  <InvoiceState state="canceled" id="canceled" color="red" />
                  Storniert am ({accountingMove.canceledName})
                </ItemLabel>
                <div>{formatDate(accountingMove.canceledAt)}</div>
              </ItemInner>
            </Item>
          )}
        </List>
        {cancellationEnabled && !isCanceled && (
          <>
            <Button
              disabled={!!error || preventCancelInvoice}
              isLoading={isLoading}
              secondary
              customTheme={{
                primaryColor: '#E30045',
                secondaryColor: '#ff9b00',
                logo: '',
              }}
              onClick={async () => {
                setModalOpen(true);
              }}
            >
              Stornieren
            </Button>
            <Modal
              contentLabel="cancel-invoice"
              title="Abrechnung stornieren"
              isOpen={modalOpen}
              onRequestClose={() => setModalOpen(false)}
            >
              <ModalInner>
                <p>Ist die Rechnung schon bezahlt?</p>
                <CancelInvoiceButtons>
                  <CancelInvoiceButton
                    secondary
                    onClick={() => cancelInvoice(false)}
                  >
                    Nein
                  </CancelInvoiceButton>
                  <CancelInvoiceButton onClick={() => cancelInvoice(true)}>
                    Ja
                  </CancelInvoiceButton>
                </CancelInvoiceButtons>
                <CancelInvoiceHint>
                  Vorsicht: Diese Aktion kann nicht rückgängig gemacht werden!
                </CancelInvoiceHint>
              </ModalInner>
            </Modal>
            {preventCancelInvoice && (
              <ButtonHint>
                Die Rechnung wurde bereits gezahlt und kann nur über unseren
                Service Desk storniert werden. Bitte schreib uns unter Angabe
                der Vertrags- und Rechnungsnummer über unser Ticketsystem.
              </ButtonHint>
            )}
          </>
        )}
      </ModalWrap>
    </Modal>
  );
}

function renderBookings(bookings: Booking[], isCanceled: boolean) {
  // If the invoice has been canceled, we only show the original
  // invoice amount
  const bookingsToShow = isCanceled
    ? bookings.filter((b) => b.type === BookingType.Settlement)
    : [...bookings];

  return bookingsToShow
    .sort((el1, el2) => {
      // Settlement should always come first
      if (el1.type === BookingType.Settlement) {
        return -1;
      }
      if (el2.type === BookingType.Settlement) {
        return 1;
      }
      // SettlementRemaining should always come last
      if (el1.type === BookingType.SettlementRemaining) {
        return 1;
      }
      if (el2.type === BookingType.SettlementRemaining) {
        return -1;
      }
      // The rest should be sorted by date

      return DateTime.fromISO(el1.date) < DateTime.fromISO(el2.date) ? -1 : 1;
    })
    .map((booking) => {
      const key = `${booking.type}-${booking.date}-${booking.amount}`;
      switch (booking.type) {
        case BookingType.VoucherKb:
          return <BookingItem key={key} booking={booking} label="Werbebonus" />;
        case BookingType.VoucherWb:
          return (
            <BookingItem key={key} booking={booking} label="Wechselbonus" />
          );
        case BookingType.DownPayment:
          return (
            <BookingItem
              key={key}
              booking={booking}
              label="Gezahlte Abschläge"
            />
          );
        case BookingType.Settlement:
          return (
            <BookingItem
              key={key}
              booking={booking}
              label="Verbrauchsabrechnung"
            />
          );
        case BookingType.SettlementRemaining:
          return (
            <BookingItem
              key={key}
              booking={booking}
              label="Verbleibender Betrag"
              bold
            />
          );
        default:
          return null;
      }
    });
}

function formatMonetaryValue(
  amount: number,
  accountType?: AccountType,
): string {
  const sign = accountType
    ? accountType === AccountType.Debit
      ? ''
      : '-'
    : '-';

  return `${sign}${formatCurrency(amount)}`;
}
