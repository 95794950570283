import { FlexRow } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

export const Amount = styled.div`
  text-align: right;
  width: 140px;
`;

export const Name = styled.div`
  margin-left: 15px;
  width: 18%;
`;

export const Sepa = styled.div`
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  color: #ababab;
  letter-spacing: 0.83px;
  font-weight: bold;
  font-size: 12px;
  margin-right: 15px;
  padding: 2px;
  width: 37px;
`;

export const SepaSpacer = styled.div`
  width: 37px;
`;

export const BookingDate = styled.div`
  width: 80px;
`;

export const Empty = styled.div`
  text-align: center;
  color: #616161;
  font-size: 15px;
  padding: 10px;
`;

export const Entry = styled.li`
  list-style: none;
  &:hover {
    ${(props) => props.onClick && 'background-color: #f3f3f3;'}
    ${(props) => props.onClick && 'cursor: pointer;'}
    border-radius: 4px;
  }
`;

export const EntryInner = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 12px 7px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 5px 10px;
  font-size: 14px;
`;
