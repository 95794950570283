import { Label } from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import styled from 'styled-components';

import { TooltipInfo } from '../../../components/TooltipInfo';
import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import {
  GraphqlFormField,
  GraphqlFormSelect,
} from '../../../components/graphql-form/render';
import { InvoiceCycle } from '../../../graphql-types';

import MultiPeriodForm from './MultiPeriodForm';
import QuarterlyDeduction from './QuarterlyDeduction';
import YearPeriodForm from './YearPeriodForm';
import { yearsUntilContractStart } from './helpers';
import { FormVariablesAccountingWorkflow } from './types';

const MutedText = styled.span`
  color: ${({ theme }) => theme.palette.textMuted};
  font-weight: 400;
`;

const getInvoiceCycleLabel = (invoiceCycle: InvoiceCycle): string => {
  switch (invoiceCycle) {
    case InvoiceCycle.Monthly:
      return 'Monatlich';
    case InvoiceCycle.Quarterly:
      return 'Vierteljährlich';
    case InvoiceCycle.HalfYearly:
      return 'Halbjährlich';
    case InvoiceCycle.Yearly:
      return 'Jährlich';
    default:
      return 'Jährlich';
  }
};

export default function InvoiceCycleForm() {
  const [yearValue, setYearValue] = React.useState<number | null>(null);

  const { formVariables } = useGraphqlForm();
  const { contractStartDate } = formVariables;
  const { values, resetForm, initialValues } =
    useFormikContext<FormVariablesAccountingWorkflow>();
  const { invoiceCycle, workflowType, paymentPeriodStartAt, name } = values;
  const contractStartYear =
    contractStartDate && DateTime.fromJSDate(contractStartDate).year;
  const firstYearAvailable =
    contractStartYear && workflowType === 'single' ? contractStartYear : 2019; // TODO: In future ticket, retrieve first year from accounting dashboard

  React.useEffect(() => {
    if (paymentPeriodStartAt && !yearValue) {
      const availableYears = yearsUntilContractStart(firstYearAvailable);
      const highestAvailableYear = Math.max(...availableYears);
      const startPaymentYear = DateTime.fromISO(paymentPeriodStartAt).year;
      const preSelectedYear =
        startPaymentYear > highestAvailableYear ? null : startPaymentYear;
      setYearValue(preSelectedYear);
    }
  }, [firstYearAvailable, paymentPeriodStartAt, yearValue]);

  return (
    <>
      <GraphqlFormSelect
        name="invoiceCycle"
        data-testid="select-invoiceCycle"
        label="Abrechnungsturnus"
        disabled={workflowType === 'single'}
        onChange={() =>
          resetForm({ values: { ...{ ...initialValues, name } } })
        }
      >
        {[
          InvoiceCycle.Monthly,
          InvoiceCycle.Quarterly,
          InvoiceCycle.HalfYearly,
          InvoiceCycle.Yearly,
        ].map((invoiceCycleValue) => (
          <option value={invoiceCycleValue} key={invoiceCycleValue}>
            {getInvoiceCycleLabel(invoiceCycleValue)}
          </option>
        ))}
      </GraphqlFormSelect>
      {invoiceCycle && (
        <>
          <h3>Abrechnungsperiode</h3>
          {invoiceCycle === InvoiceCycle.Yearly ? (
            <YearPeriodForm
              year={yearValue}
              setYear={setYearValue}
              firstYearAvailable={firstYearAvailable}
            />
          ) : (
            <MultiPeriodForm
              year={yearValue}
              setYear={setYearValue}
              firstYearAvailable={firstYearAvailable}
            />
          )}
        </>
      )}
      {workflowType === 'multiple' && (
        <>
          {/* Start AbrechnungsPeriode */}
          <GraphqlFormField
            name="paymentPeriodStartAt"
            label="Start Abrechnungsperiode"
          />
          {/* Ende AbrechnungsPeriod */}
          <GraphqlFormField
            name="paymentPeriodEndAt"
            label="Ende Abrechnungsperiode"
            disabled
          />

          <Label>
            Abrechnung auf Kundenanlagen beschränken{' '}
            <MutedText>(optional)</MutedText>
            <TooltipInfo
              id="plantid"
              text="Wähle eine oder mehrere Kundenanlagen aus um die Abrechnung auf diese Kundenanlagen zu beschränken."
            />
          </Label>
          {workflowType === 'multiple' && (
            <GraphqlFormField
              name="plantsToAccount"
              placeholder=""
              label=""
              relation="Plant"
              multiselect
            />
          )}
        </>
      )}
      {invoiceCycle === InvoiceCycle.Monthly ? null : <QuarterlyDeduction />}
    </>
  );
}
