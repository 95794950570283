import React, { useState, useCallback } from 'react';
import { merge } from 'lodash';
import * as yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { InfoAlert } from '@ampeersenergy/ampeers-ui-components';
import { Link } from 'react-router-dom';
import 'styled-components/macro';

import { EditContainerProps } from '../../../../components/createFlow';
import {
  useReadNextLabelQuery,
  ReadPlantMetersDocument,
  PlantDetailExtendedDocument,
  ReadMkvTemplatesDocument,
  ContractType,
} from '../../../../graphql-types';
import GraphqlMultiStepForm from '../../../../components/graphql-form/multistepForm';
import { CustomerForm, ContractForm } from '../sharedFormParts';
import { PaymentForm } from '../PaymentForm';
import { customerExists, contractExists } from '../labelChecks';
import OptionalField, {
  OptionalFieldStyling,
} from '../../../../components/optionalField';
import { GraphqlFormField } from '../../../../components/graphql-form/render';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import {
  yupBankAccount,
  yupBankAccountNullable,
} from '../../../../helpers/validators/bankAccount';
import { contractStatusValidator } from '../formParts/contractStatus/contractStatus';
import AddressesForm from '../AddressesForm';

export default function CreateMeasurementContract({
  onSuccess,
  onAbort,
  variables,
  formVariables,
  defaultValues,
  values,
}: EditContainerProps) {
  const { data: customerLabel, loading: customerLabelLoading } =
    useReadNextLabelQuery({
      variables: {
        type: 'customer',
      },
      fetchPolicy: 'network-only',
    });
  const { data: contractLabel, loading: contractLabelLoading } =
    useReadNextLabelQuery({
      variables: {
        type: 'contract',
      },
      fetchPolicy: 'network-only',
    });
  const client = useApolloClient();

  const fixedOpenEndDateState = useState(!!defaultValues?.endDate);
  const billingState = useState(true);
  const [reuseShippingAddress, setReuseShippingAddress] = useState(
    !defaultValues?.customer?.addressShipping?.streetName,
  );
  const consumptionFirstOptionSelectionState = useState(true);
  const [existingCustomerState, setExistingCustomerState] = useState(true);
  const customerLabelState = useState(false);
  const contractLabelState = useState(false);
  const customerPersonState = useState(true);
  const billingCustomerPersonState = useState(true);
  const [shippingCustomerPersonState, setShippingCustomerPersonState] =
    useState(true);
  const billingCustomerAdditionalEntityFieldsState = useState(false);
  const billingCustomerAdditionalPersonState = useState(true);
  const selfPayerState = useState(true);

  const resetCustomerStates = useCallback(() => {
    customerLabelState[1](false);
    billingState[1](true);
    customerPersonState[1](true);
    billingCustomerPersonState[1](true);
    billingCustomerAdditionalEntityFieldsState[1](false);
    billingCustomerAdditionalPersonState[1](true);
  }, [
    customerLabelState,
    billingState,
    customerPersonState,
    billingCustomerPersonState,
    billingCustomerAdditionalEntityFieldsState,
    billingCustomerAdditionalPersonState,
  ]);

  const secondNextLabel = (label: string): string => {
    const lastNumber = label.match(/\d+$/)?.[0] || '0';
    const lastNumberLength = lastNumber.length;
    const nextNumber = (BigInt(lastNumber) + BigInt(1))
      .toString()
      .padStart(lastNumberLength, '0');

    return label.replace(/\d+$/, nextNumber);
  };

  const steps = [
    {
      title: 'Vertragspartner',
      content: (
        <>
          {formVariables.isPrefilled ? (
            <InfoAlert>
              Das Formular wurde mit der Vorlage für Gebäude Verträge
              vorausgefüllt.{' '}
              <Link to="/settings/contracts">Vorlage bearbeiten</Link>
            </InfoAlert>
          ) : null}
          <CustomerForm
            fieldNamePrefix=""
            customerPersonState={customerPersonState}
            customerLabelState={customerLabelState}
            existingCustomerState={[
              existingCustomerState,
              setExistingCustomerState,
            ]}
            resetCustomerStates={resetCustomerStates}
          />
        </>
      ),
    },
    {
      title: 'Adressen',
      content: (
        <AddressesForm
          addresses={variables.addresses ?? []}
          fieldNamePrefix=""
          billingState={billingState}
          shippingState={[reuseShippingAddress, setReuseShippingAddress]}
          billingCustomerPersonState={billingCustomerPersonState}
          billingCustomerAdditionalEntityFieldsState={
            billingCustomerAdditionalEntityFieldsState
          }
          billingCustomerAdditionalPersonState={
            billingCustomerAdditionalPersonState
          }
          shippingCustomerPersonState={[
            shippingCustomerPersonState,
            setShippingCustomerPersonState,
          ]}
          existingCustomerState={[
            existingCustomerState,
            setExistingCustomerState,
          ]}
          customerPersonState={customerPersonState}
        />
      ),
    },
    {
      title: 'Vertrag',
      content: (
        <>
          <ContractForm
            fieldNamePrefix=""
            fixedOpenEndDateState={fixedOpenEndDateState}
            consumptionFirstOptionSelectionState={
              consumptionFirstOptionSelectionState
            }
            contractLabelState={contractLabelState}
            disabledStartAndEndDates={
              formVariables.formState === 'second-contract'
            }
          />
          {!formVariables.isSumMeter && (
            <GraphqlFormField name="electricityGenerationOrGasType" />
          )}
          {formVariables.meterType &&
          formVariables.meterType !== 'sumMeterConsumption' ? (
            <GraphqlFormField
              name="storeAsMkvTemplate"
              label={
                formVariables.isPrefilled
                  ? `Bestehende Vorlage für Gebäudeverträge überschreiben`
                  : `Als Vorlage für weitere Gebäudeverträge speichern`
              }
              css="padding-top: 1rem;"
            />
          ) : null}
        </>
      ),
    },
    ...(!formVariables.isSumMeter
      ? [
          {
            title: 'Abrechnung',
            content: (
              <OptionalField
                label="Abrechnungsrelevanter Vertrag"
                options={['Nein', 'Ja']}
                test-id="payment"
                managedState={billingState}
              >
                <OptionalFieldStyling>
                  <PaymentForm
                    fieldNamePrefix=""
                    selfPayerState={selfPayerState}
                    shouldResetFieldState={billingState}
                  />
                </OptionalFieldStyling>
              </OptionalField>
            ),
          },
        ]
      : []),
  ];

  const { meterType } = formVariables;

  return (
    <GraphqlMultiStepForm
      mutation={`${
        formVariables.isSumMeter
          ? 'createMeasurementContract'
          : 'createContract'
      }`}
      steps={steps}
      variables={{ ...variables, meterType }}
      formVariables={{
        ...formVariables,
        useCase: 'createContract',
        isOperator:
          formVariables.isOperator === undefined
            ? false
            : formVariables.isOperator,
        label:
          formVariables.formState === 'second-contract'
            ? secondNextLabel(contractLabel?.readNextLabel || '')
            : contractLabel?.readNextLabel,
        'customer.label':
          defaultValues?.customer?.label ?? customerLabel?.readNextLabel,
      }}
      isLoading={contractLabelLoading || customerLabelLoading}
      onSuccess={(result, add) => {
        if (onSuccess) {
          onSuccess(result, add);
        }
      }}
      formatBeforeSubmit={(v) => {
        return {
          ...v,
          label: v.label.trim(),
          contractMeter: {
            meter: { id: v.contractMeter.meter.id },
          },
          type: ContractType.MeasurementConcept,
        };
      }}
      onAbort={onAbort}
      defaultValues={merge({}, defaultValues, {
        label: contractLabel?.readNextLabel ?? '',
        customer: {
          label:
            defaultValues?.customer?.label ??
            customerLabel?.readNextLabel ??
            '',
        },
        /**
         * graphql forms sets initial values for all keys.
         * In this case the initital value is `false`. During
         * submit only empty strings `""`, `null` and `undefined` are filtered and
         * removed from the payload. meterReading is not used in this flow
         * so we can safely unset `ignoreWarning` otherwise it will
         * throw an error.
         */
        contractMeter: {
          meterReading: {
            ignoreWarning: '',
          },
        },
      })}
      labels={{
        submit: 'Vertrag anlegen',
      }}
      readDocumentFields={
        formVariables.isSumMeter
          ? [
              'success',
              'odooContractId',
              'feedInContract {id}',
              'consumptionContract {id}',
            ]
          : ['id']
      }
      values={values}
      refetchQueries={[
        {
          query: PlantDetailExtendedDocument,
          variables: {
            plantId: formVariables.plantId,
            projectId: formVariables.projectId?.toString(),
          },
        },
        {
          query: ReadPlantMetersDocument,
          variables: {
            plantId: formVariables.plantId,
          },
        },
        {
          query: ReadMkvTemplatesDocument,
        },
      ]}
      validation={{
        startDate: yupUTCDate.required(),
        customer: {
          label: yup
            .string()
            .required()
            .test(
              'valid-customerLabel',
              'Kunden-Nr existiert bereits',
              customerExists(client),
            ),
          documentDeliveryMethod: yup.string().required(),
          existingCustomer: {
            id: yup.string().test('id', 'Pflichtfeld', (value: any) => {
              return !existingCustomerState || Boolean(value);
            }),
          },
          ...(!formVariables.isSumMeter && {
            bankAccount: yup.object().when('hasSepa', () => {
              if (!selfPayerState[0]) {
                return yupBankAccount;
              }
              return yupBankAccountNullable;
            }),
          }),
        },
        ...(!formVariables.isSumMeter && {
          electricityGenerationOrGasType: yup.string().required(),
        }),
        label: yup
          .string()
          .required()
          .test(
            'valid-contractLabel',
            'Diese Vertragsnummer existiert bereits',
            async (_contractLabel) =>
              (await contractExists(client)(_contractLabel)) &&
              (formVariables.formState !== 'second-contract' ||
                _contractLabel !== formVariables.sumMeterContractLabelState[0]),
          ),
        status: contractStatusValidator,
      }}
    />
  );
}
